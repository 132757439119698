
* {
  	box-sizing: 					border-box;
}

:root {

	/* 
	--primary-color: 				#362314;
	--primary-color-transparant:	rgba(255, 255, 255, 0.0);
	--secondary-color: 				#f9d897;
	--tertiary-color: 				#e09745;
	--quaternary-color: 			#c2623e; 
	*/

	--primary-color: 				#FCFEFD;
	--primary-color-transparant:	rgba(255, 255, 255, 0.0);
	--secondary-color: 				#303B4C;
	--tertiary-color: 				#556067;
	--quaternary-color: 			#00AEEB;
}


::-webkit-scrollbar {
	width: 							12px; 								/* Breedte van de scrollbar */
}		

::-webkit-scrollbar-track {		
  	/* background: 					var(--quaternary-color); */
	background: 					var(--tertiary-color); 			/* Achtergrond van de scrollbar-track */
}		

::-webkit-scrollbar-thumb {		
	background-color: 				var(--primary-color);

	border-radius: 					6px;
	/* border: 						3px solid var(--quaternary-color); */
	border: 						3px solid var(--tertiary-color);
}

::-webkit-scrollbar-thumb:hover {
  	background: 					#555; 								/* Kleur van de scrollbar bij hover */
}

html, body {
    margin: 0; /* Verwijdert standaard marges */
    padding: 0; /* Verwijdert standaard padding */
    overflow-x: hidden; /* Voorkomt horizontale scroll */
    width: 100%; /* Zorgt ervoor dat de body de volledige breedte heeft */
    height: 100%; /* Zorgt ervoor dat de body de volledige hoogte heeft */
}

.container {
    text-align: center; /* Centreert de inhoud */
    width: 100%; /* Volledige breedte van het scherm */
    padding: 1rem; /* Padding voor ademruimte */
    box-sizing: border-box; /* Zorg ervoor dat padding wordt meegerekend in de breedte */
    overflow: hidden; /* Voorkomt dat de inhoud buiten de container valt */
}

/* Media query voor kleine schermen */
@media only screen and (max-width: 768px) {
    .container {
        padding: 0.5rem; /* Verminder padding voor kleinere schermen */
        /* Optioneel: je kunt ook de font-size aanpassen als dat nodig is */
        /* font-size: 0.9rem; */
    }
}

/* 
body {
	min-width: 				fit-content;
  	margin: 				0;
	background:				var(--primary-color);
	font-family:			"Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
	font-size: 				16px;
	letter-spacing: 		1px;
} */

h1 {
	color:					var(--tertiary-color);
}

h2 {
	color:					var(--tertiary-color)
}

button {
	/* background: 			var(--quaternary-color); */
	background: 			var(--tertiary-color);
	color: 					var(--tertiary-color);
	height:					20px;
}



/**************************************************************
	NavBar
**************************************************************/

.site-title {
	
	/* color:					var(--secondary-color) !important; */
	color:					var(--primary-color) !important;
  	font-size: 				3rem;
	font-weight: 			bold;
	letter-spacing: 		6px;
	
	transition: 			font-size 0.5s ease, transform 0.5s ease; 
}

.site-title.large {
	font-size: 				3rem;
	/* transform: 				scale(1); 	 */
}

.site-title.small {
	font-size: 				1.5rem;
	/* transform: 				scale(0.85); 	 */
}

.link-icon {
	height: 				1em; /* Maak de afbeelding even hoog als de tekst */
	width: 					auto; /* Houd de verhouding van de afbeelding intact */
	margin-right: 			2rem; /* Ruimte tussen afbeelding en tekst */
	display: 				inline-block;
}

.nav {
	display: 				flex;

	position: 				sticky;
	top: 					0;
	z-index: 				1000;

	/* background: 			var(--primary-color); */
	background: 			var(--secondary-color);
	/* color:					var(--tertiary-color); */
	color:					var(--primary-color);

	justify-content:		flex-start;
	gap: 					2rem;
	padding: 				0rem 8rem;
	padding-top: 			1rem;
	padding-bottom: 		1rem;
	
	/* experimenteel */
	transition: 			background 0.3s ease, padding 0.3s ease; /* Zorgt voor een vloeiende overgang */
}

.nav.large {

	/* Onderstaande geldt voor position: absolute */
	position: 				absolute;
	left: 					0;
	right: 					0;						/* Navbar wordt naar rechts gepositioneerd */
	top: 					0;						/* Als je de navbar aan de bovenkant van het scherm wil */
	width: 					100%; 					/* Als je wil dat de nav de hele breedte beslaat, anders kan je een vaste breedte geven */
	/* Bovenstaande geldt voor position: absolute */

	z-index: 				10;

	padding: 				2rem 8rem; 				/* Grotere padding */

	/* background: 			#FFF000; */
	/* color: 					#FF0000; */

	/***

		[ ] hoe kan ik doorschijnend combineren met vars?

			[ ] gewoon als aparte var?

	*/
	/* background: 			rgba(255, 255, 255, 0.0); */
	background:				var(--primary-color-transparant);
	/* background: 			var(--primary-color) */
}

.nav.small {
	/* background: 			#FFFFFF; */
	background: 			var(--primary-color);
	padding: 				1rem 4rem; 				/* Kleinere padding */
}

.nav-seethrough {
	position: 				fixed; /* Zorgt ervoor dat de NavBar bovenaan het scherm blijft */
	top: 					0;
	left: 					0;						
	width: 					100%; 							/* Zorgt ervoor dat de NavBar de volledige breedte van het scherm heeft */
	/* background-color: 	 	rgba(0, 0, 0, 0.5);			Zwarte kleur met 50% transparantie */
	/* background-color: 	 	rgba(0, 0, 0, 0); */
	background-color: 		var(--primary-color-transparant);

	z-index: 				10; 							/* Zorgt ervoor dat de NavBar altijd boven de andere elementen wordt weergegeven */
	/* color: 					white; */
	color:					var(--primary-color);
	padding: 				10px 20px; /* Optioneel: padding voor wat ruimte binnen de NavBar */
	/* backdrop-filter: 		blur(5px); */
	/* backdrop-filter: 		blur(0px); */
}

.nav-seethrough ul {
	margin: 				0;
	list-style: 			none;
	display: 				flex;
	gap: 					1rem;
}

.nav-seethrough a {
	color: 					inherit;
	text-decoration: 		none;
	height: 				100%;
	display: 				flex;
	align-items: 			flex-end;
	padding: 				.25rem;
}

.nav-seethrough li {
	flex-shrink:			0;
}

.nav-seethrough li.active {
	/* border-bottom: 			2px solid #FF0000; */
	border-bottom: 			2px solid var(--tertiary-color);
}

.nav-seethrough li:hover {
	/* border-bottom: 			1px solid #FF0000; */
	border-bottom: 			1px solid var(--tertiary-color);
}

/* default nav menu */
.nav-menu {
	margin: 				0;
	list-style: 			none;
	display: 				flex;
	gap: 					1rem;
	margin-left: 			auto; 					/* Duwt de ul helemaal naar rechts */
}

.menu-button {
	display: 				none; /* Verberg de menu-knop op grotere schermen */
}

.nav-menu li {
  	margin: 				10px 0; /* Ruimte tussen menu-items */
}

.nav a {
	color: 					inherit;
	text-decoration: 		none;
	height: 				100%;
	display: 				flex;
	align-items: 			flex-end;
}

.nav li {
	flex-shrink:			0;
}

.nav li.active {
	/* border-bottom: 			2px solid var(--tertiary-color); */
	border-bottom: 			2px solid var(--primary-color);
}

.nav li:hover {
	/* border-bottom: 			1px solid var(--tertiary-color); */
	border-bottom: 			1px solid var(--primary-color);
}

.offcanvas {
	display:				none;
}



/* grote schermen */
@media only screen and (min-width: 600px) {
	/* expliciet verbergen op grote schermen */
	.offcanvas-close-button {
		display:				none;
	}
}

/* medium schermen van 601 tot 1000 pixels */
@media only screen and (min-width: 601px) and (max-width: 950px) {
	.nav {
		flex-direction: 		column;
		gap: 					1rem;
		align-items: 			flex-start; 	/* Zorgt ervoor dat de items links uitgelijnd zijn */
		padding: 				1rem 2rem; 		/* Pas de padding aan voor medium schermen */
	}

	.nav ul {
		margin-left: 			0;
		display: 				flex;
		flex-direction: 		row; 			/* Houdt de links horizontaal */
		gap: 					1rem; 			/* Standaard ruimte tussen de lijstitems */
		width: 					100%;
		justify-content: 		flex-start;	 	/* Zorgt ervoor dat de links aan de linkerkant blijven */
	}
}


@media only screen and (max-width: 600px) {

	.nav {
		
		height: 				60px; 			/* Vaste hoogte */
		position: 				sticky;

		display: 				flex;
		flex-direction:			row;

		justify-content: 		flex-start;
		align-items: 			center;

		gap: 					0;
		padding: 				0;

		box-shadow: 			0 4px 6px rgba(0, 0, 0, 0.1);
	}
		
	.site-title {
		flex-grow: 				1;
		display:				flex;
		line-height: 			60px;		/* Zet line-height gelijk aan de hoogte van de nav-bar */
		font-size: 				2rem;
	}

	.site-title.large {
		flex-grow: 				1;
		display:				flex;
		line-height: 			60px;	
		font-size: 				2rem;
	}

	.site-title.small {
		flex-grow: 				1;
		display:				flex;
		line-height: 			60px;	
		font-size: 				2rem;
	}

	.nav-menu {
		display:				none;
	}
	
	/* .nav ul {
		padding-left: 			0;
		display: 				flex;
	} */

	
	/* .nav a {
		padding: 				0;
	} */

	.menu-button {
		display: 				block;
		font-size: 				24px;
		background: 			none;
		color:					var(--primary-color);
		border: 				none;
		cursor: 				pointer;

		height: 				60px; 			/* Zelfde hoogte als navbar */
		width: 					60px; 			/* Vierkante knop */
		flex-shrink: 			0; 				/* Voorkom dat de knop krimpt of uitzet */
		margin: 				0; 				/* Verwijder eventuele marges */
		padding: 				0; 				/* Verwijder padding als er iets toegevoegd is */
	}


	/* Off-canvas menu stijl voor kleine schermen */
	.offcanvas {
		display:				flex;
		position: 				fixed;
		align-items: 			flex-start;

		top: 					0;
		left: 					-250px; 			/* Plaats buiten het scherm aan de linkerkant */
		width: 					250px;
		height: 				100%;
		/* background-color: 		#fff; */
		background-color: 		var(--tertiary-color);
		box-shadow: 			2px 0 5px rgba(0, 0, 0, 0.3);
		transition: 			transform 0.3s ease-in-out;
		overflow-y: 			auto;
		z-index: 				1000;
		padding-top: 			20px;
	}

	/* Open klasse om het menu naar binnen te schuiven */
	.offcanvas.open {
		transform: 				translateX(250px); 			/* Schuif het menu naar binnen */
	}

	.offcanvas-close-button {
		font-size: 				24px;
		background: 			none;
		border: 				none;
		cursor: 				pointer;
		position: 				absolute;
		top: 					10px;
		right: 					10px;
		color: 					var(--primary-color);
	}

	.offcanvas-list {
		display: 				flex;
		list-style: 			none;
		flex-direction: 		column;
		padding: 				0;
		gap: 					1rem;
		margin: 				20px;
	}

	.offcanvas-list li {
		/* margin-left: 			20px; */
		/* margin: 				10px 0; */
	}
}

.footer {
    
	position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px; /* Pas deze hoogte naar wens aan */
    background-color:  var(--secondary-color); /* Kies een kleur die opvalt */
    text-align: center; /* Optioneel, voor centreren van de inhoud */
    padding: 10px 0; /* Optioneel, voor extra ruimte binnenin */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optioneel, voor visueel effect */
}


/**********************************************************************


	Home page

**********************************************************************/

.home-container {
    display: 			flex;
    flex-direction: 	column; 		/* Zet de flex richting op kolom */
    width: 				100%; 					/* Zorg ervoor dat de container de volle breedte van het scherm gebruikt */
    max-width: 			1200px; 				/* Optioneel: Maximaal schermbreedte als dat gewenst is */
    margin: 			0 auto; 				/* Center de container horizontaal */
    box-sizing: 		border-box; 		/* Zorg ervoor dat padding en border binnen de breedte en hoogte worden geteld */
}

/* Media query voor kleine schermen */
@media only screen and (max-width: 900px) {
    .home-container {
        padding: 0.5rem; /* Verminder padding voor kleinere schermen */
    }
}


.cover-container {

	display: 			flex;
	flex-direction: 	row;
	justify-content: 	space-around; /* Verdeel items gelijkmatig langs de horizontale as */

	padding-top: 		2rem;
}

.image-container {
	width: 			100%;
	max-width: 		60%;
	height: 		auto;
	object-fit: 	contain; /* Zorgt ervoor dat de afbeelding zijn aspect ratio behoudt */
}

.welcome-container {
	padding: 		2rem;
	margin-right: 	4rem;
	flex-grow: 		1;
	align-self: center;
	/* align-self: 	flex-end; / * Plaats de afbeelding aan de onderkant van de div * / */
}

.promo-container {
    /* height: 400px; */
    text-align: left;
    flex-grow: 1;
    /* align-items: stretch; */
    /* align-items: flex-end; */
    /* background: #3D0000; */

    /* margin-top: 6rem; */
    /* margin-top: 20vh; */
    padding: 2rem;
    margin-right: 2rem;

    align-self: flex-end; /* Plaats de afbeelding aan de onderkant van de div */

    /* Test border */
    /* border: 1px solid #0000FF; */
}

@media only screen and (max-width: 600px) {

    .cover-container {
		height: auto; /* Laat de hoogte automatisch aanpassen */
		flex-direction: column; /* Maak de flexrichting kolom */
		padding: 1rem; /* Voeg padding toe voor kleine schermen */

		justify-content: center; /* Plaats het item in het horizontale midden */
		align-items: center; /* Plaats het item in het verticale midden */
    }

    .image-container {
        max-width: 100%; /* Laat de afbeelding de volledige breedte innemen */
		margin-bottom: 1rem; /* Voeg wat ruimte onder de afbeelding toe */
    }

	.welcome-container {
		padding: 0rem;
		text-align: left;
		margin: 0 auto; /* Centeren binnen de container */
		width: 100%; /* Volledige breedte */
	}

	.promo-container {
		text-align: left;
		flex-grow: 1;
	}
}


.join-button {
	display: 				inline-block;
	padding: 				1rem 2rem;
	/* background-color: 		#950101; */
	background-color: 		var(--secondary-color);
	/* color: 					#fff; */
	color:					var(--primary-color);
	font-size: 				2rem; 							/* Grote tekstgrootte */
	font-weight: 			bold;
	text-align: 			center;
	text-decoration: 		none; 							/* Verwijder de onderstreping van de link */
	border-radius: 			5px; 							/* Ronde hoeken voor de knop */
	transition: 			background-color 0.3s ease, transform 0.3s ease; /* Animatie voor hover-effect */
}

.join-button:hover {
	/* background-color: 		#750000; */
	/* background-color: 		var(--quaternary-color); */

	transform:				scale(1.05); /* Lichte vergroting bij hover */
}

.join-button:active {
	transform: 				scale(0.98); /* Lichte verkleining wanneer ingedrukt */
}



/**********************************************************************

	Login page

**********************************************************************/

.form-container {
	display: 				flex;
	flex-direction: 		column;
	align-items: 			center;
	justify-content: 		center;
	padding: 				1rem;
	width: 					300px;
	margin: 				0 auto;
	/* border: 1px solid #ccc; */
	/* border-radius: 8px; */
	/* background-color: #f9f9f9; */
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.login-header {
  /* font-size: 1.5rem; */
  /* margin-bottom: 1rem; */
  /* color: #333; */
}

.text-input {
	width: 			100%;
	padding:		0.75rem;
	margin-bottom: 	1rem;

	border:			1px solid var(--secondary-color);
	border-radius: 	4px;
	font-size: 		1rem;
	/* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); */
}

.text-input:focus {
	/***
		[ ] zelfs 6e kleur nemen??
	*/
	border-color: #007BFF;
	outline: 		none;
	/* box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); */
}

.form-button {
	
	width: 100%;
	padding: 1rem;
	font-size: 1rem;

	/* background-color: #007BFF; */
	/* background-color: 	#FFF; */
	background-color: 		var(--primary-color);
	/* color: 				#950101; */
	color: 				var(--secondary-color);
	/* border: 			2px solid #950101;  */
	border: 			2px solid var(--secondary-color);
	border-radius: 		8px;
	cursor: 			pointer;
	display: 			flex;
	justify-content: 	center; /* Horizontale centrering van de tekst */
	align-items: 		center;     /* Verticale centrering van de tekst */
	transition: 		background-color 0.3s ease; /* Mooie overgang bij hover */
}

.form-button:hover {
  /* background-color: #0056b3; */
}

.form-button.disabled {
  /* background-color: #ccc; */
  cursor: 				not-allowed;
}

/* divider voor inlog opties */
.divider-container {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 300px; /* Maak het smaller naar wens */
	margin: 0 auto; /* Centreer de container horizontaal */
}

.line {
	flex-grow: 1; /* Vult de beschikbare ruimte binnen de max-width */
	height: 1px;
	background-color: #ccc;
	margin: 0 1rem; /* Ruimte aan weerszijden van "or" */
}

.divider-container p {
	margin: 0;
	padding: 0;
	color: #888; /* Optionele kleur voor de tekst */
	white-space: nowrap; /* Zorgt ervoor dat "or" niet afbreekt */
}

/***
	grid
*/
.grid-container {
	display: 						grid;
	/* grid-gap: 						1rem; */
	
	column-gap: 					100px;
	row-gap: 						100px;
	
	grid-template-columns:	 		1fr 1fr 1fr;

	padding-left: 					6rem;
	padding-right: 					6rem;
	
	/* background-color: 				#0056b3; */
}


/* Media query voor medium schermen */
@media only screen and (max-width: 1000px) {
	.grid-container {
		grid-template-columns: 		1fr 1fr; 	/* Eén kolom voor kleine schermen */
		padding-left: 				1rem; 		/* Pas padding aan voor kleinere schermen */
		padding-right: 				1rem; 		/* Pas padding aan voor kleinere schermen */
	}
}

/* Media query voor kleine schermen */
@media only screen and (max-width: 600px) {
	.grid-container {
		grid-template-columns: 		1fr; 		/* Eén kolom voor kleine schermen */
		padding-left: 				1rem; 
		padding-right: 				1rem; 
	}
}

.tile-wrapper {
	/* padding: 						10px; */
	/* background: 					#000000; */
	
	box-sizing: 					border-box;
	resize: 						horizontal;
	/* border: 						1px dashed; */
	max-width: 						100%;
	/* height: calc(100vh - 16px); */
}

.tile {
	width: 							100%;
	/* padding-bottom: 				75%; */
	/* background: 					rgb(129, 104, 104); */
}



/***
	card
*/
.card-container {
	position: 						relative;
	text-align: 					center;
	/* color: 							white; */
	color: 							var(--primary-color);
}

.card-image {
	width:							100%;
	border-radius:					10px;
}

.bottom-left {
	position: 						absolute;
	bottom: 						8px;
	left: 							16px;
}

.top-left {
	position: 						absolute;
	top: 							8px;
	left: 							16px;
}

.top-right {
	position: 						absolute;
	top: 							8px;
	right: 							16px;
}

.bottom-right {
	position: 						absolute;
	bottom: 						8px;
	right: 							16px;
}

.centered {
	position: 						absolute;
	top: 							50%;
	left: 							50%;
	transform: 						translate(-50%, -50%);
}

.bottom-centered {
	position: 						absolute;
	bottom: 						8px;
	left: 							50%;
	transform: 						translate(-50%, -50%);
}

/***************

	lessons page

***************/

.video-playlist-container {
	display: 				flex;
	gap: 					2rem;
	align-items: 			flex-start; 	/* Verticale as */
	justify-content: 		center; 		/* Horizontale as */
	padding: 				40px;

	/* test */
	/* background-color: 		var(--secondary-color);
	border: 				4px solid #FFFF00; */
}

.video-container {
	flex:					1;
	max-width:				800px;
}

.playlist-container {
	height: 				360px; /* De hoogte is gelijk aan de videoPlayer */
	overflow-y: 			auto; /* Zorgt ervoor dat de inhoud van de div verticaal kan scrollen */
	padding: 				1rem;
	background-color: 		var(--primary-color);
}


@media only screen and (max-width: 1200px) {

	.video-playlist-container {
		gap: 					0rem;
		padding: 				10px;
	}

	.playlist-container {
		padding: 				1rem;
	}
}

@media only screen and (max-width: 1000px) {

	.video-playlist-container {
		flex-direction: 		column;
		/* align-items: 			center;  */
		align-items: 			flex-start;
	}
	
	.video-container {
		width: 					100%; /* Of een percentage dat past */
		max-width: 				800px;
	}

	.playlist-container {
		width: 					100%;
		margin-top: 			1rem;
		padding: 				1rem;
	}
}



/**********************************************************************

	Product detail page

**********************************************************************/
.product-container {
    display: 			flex;
    flex-direction: 	row;
    justify-content: 	center;
    align-items: 		flex-start;
    width: 				100%;
    gap: 				2rem;

	border: 			'1px solid #00FF00',
}

.product-description-container {
	display:			flex;
    width: 				50%;
	flex-direction:		column;
	align-items:		flex-end;

    padding: 			1rem;
    
	/* border: 			1px solid #00FFFF; */
}

.product-order-container {
	display:			block;

    width: 				30%;
    padding: 			1rem;
    margin-right: 		2rem;
    border-radius: 		20px;
}

/* Voor kleinere schermen */
@media (max-width: 768px) {
    .product-container {
        flex-direction: 		column;
        align-items: 			center; /* Centreer de containers in het midden */
    }

    .product-description-container, 
    .product-order-container {
		/* align-items:		flex-start; */
		align-items:		center;
        width: 					100%; /* Zorg ervoor dat beide containers 100% van de breedte hebben */
        margin-right: 			0; /* Verwijder de rechter marge van de buy-container */
    }
}



/**********************************************************************

	Carousel

***********************************************************************/
.carousel-container {
	padding-left: 		120px;
	padding-right: 		120px;
	padding-top: 		120px;
	padding-bottom: 	60px;
	/* height:			100px; */
  	/* background: 		var(--quaternary-color); */
	background: 		var(--tertiary-color);
	
}

.slick-slide img {
  	margin: 		auto;
	/* margin: 		10px; */
}

.carousel-image {
	/* .. */
	height:			300px;
}